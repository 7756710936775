.active {
  @apply border-b-2 border-blue-200 no-underline font-bold text-white;
}


Link.link-wrapper > a.active {
    display: block;
    width: 100%;
    height:100%;
    color: white;
    background-color: blue;
}


.full1 {
  height: 70vh;
  width: 50vw;
  
}